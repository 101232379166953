import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { NumberIncrementer } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "numberincrementer"
    }}>{`NumberIncrementer`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import NumberIncrementer from 'mfcl/NumberIncrementer'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Implements a number incrementer that increases and decreases the number displayed.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={NumberIncrementer} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <p>{`You can pass a label prop to the component`}</p>
    <Playground __position={1} __code={'<div\n  style={{\n    display: \'flex\',\n    flexDirection: \'column\',\n    alignItems: \'flex-start\',\n  }}\n>\n  <NumberIncrementer name=\"Price\" />\n  <span style={{ margin: \'15px 0\' }} />\n  <NumberIncrementer name=\"Quantity\" label=\"Qty\" />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      NumberIncrementer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}>
    <NumberIncrementer name="Price" mdxType="NumberIncrementer" />
    <span style={{
          margin: '15px 0'
        }} />
    <NumberIncrementer name="Quantity" label="Qty" mdxType="NumberIncrementer" />
  </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      